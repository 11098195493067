// react
import React, { useEffect, useMemo, useState } from "react";
import axios from "utils/axios";

// material-ui
import {
    Chip,
    IconButton,
    Box,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
} from "@mui/material";
// ant design
import { EditTwoTone, RedoOutlined } from "@ant-design/icons";

// project import
import MainCard from "components/MainCard";
import CircularProgress from "@mui/material/CircularProgress";
import { WarningAlert, ErrorAlert } from "components/Alerts";
import AccessionDetail from "components/AccessionDetail";
import AccessionOptionMenu from "components/AccessionOptionMenu";
import DetailDialog from "components/DetailDialog";
import CustomTabs from "components/AccessionDetailTabs";
import { stageIndex } from "utils/xrefs";
import ConfirmDialog from "components/ConfirmDialog";
import { MUIBackendTable } from "components/PaginatedDataTable";

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { FormatDateUTC } from "utils/formatting";

dayjs.extend(utc);


function GetStatusChip({ row, size = "small" }) {
    if (row.LatestStageError !== "") {
        return <Chip label={row.LatestStageError} color="error" size={size} />;
    } else if (row.LatestStageError !== null && row.LatestStageError !== "" && row.LatestStageProcessed) {
        return <Chip label={"Complete w/ Errors"} color="warning" size={size} />;
    } else if (row.LatestStageProcessed) {
        return <Chip label={"Complete"} color="success" size={size} />;
    } else if (row.LatestStageBypassed) {
        return <Chip label={"Bypassed"} color="warning" size={size} />;
    } else if (row.Billed) {
        return <Chip label={"Billed"} color="success" size={size} />;
    } else {
        return <Chip label={"Pending"} color="primary" size={size} />;
    }

};

function ClaimScrubbingPage() {
    const [selectedAccessions, setSelectedAccessions] = useState([]);
    const [editAccession, setEditAccession] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState(null);
    const [warning, setWarning] = useState(null);
    const [actionMenuOpen, setActionMenuOpen] = useState(false);
    const [action, setAction] = useState("");
    const [stage, setStage] = useState(null);

    const columns = useMemo(() => [
        { field: "ID", headerName: "ID", width: 40, type: 'number' },
        { field: "CreatedAt", headerName: "Created", width: 125, type: "date", valueGetter: (params) => new Date(params?.row?.CreatedAt) },
        { field: "Code", headerName: "Accession", width: 125 },
        { field: "ProcedureTypes", headerName: "Test Types", width: 125 },
        {
            field: "DateOfService", headerName: "Date of Service", width: 125, type: "date",
            valueGetter: ({ value }) => value && dayjs.utc(value).toDate(),
            valueFormatter: ({ value }: { value: any }) => { return value && FormatDateUTC(value) },

        },
        { field: "CurrentStage", headerName: "Stage", width: 135, valueGetter: (params) => stageIndex[params?.row?.LatestStageID], },
        { field: "Status", headerName: "Status", width: 135, renderCell: (params) => <GetStatusChip row={params.row} />, filterable: false, sortable: false },
        { field: "CurrentStageDetail", headerName: "Detail", width: 135, valueGetter: (params) => params?.row?.LatestStageDetail },
        // { field: "DOS", headerName: "DOS", width: 95, valueGetter: (params) => params.row.DOS.slice(0, 10) },
        { field: "AssignedToClient", headerName: "Assigned", width: 155, valueGetter: (params) => params.row.AssignedToClient ? "Client" : "SBS", filterable: false },
        { field: "PerformingLab", headerName: "Performing Lab", width: 155, valueGetter: (params) => params.row.PerformingLab },
        { field: "ClientName", headerName: "Client", width: 155 },
        { field: "ClientAccountName", headerName: "Account", width: 95 },
        { field: "Provider", headerName: "Provider", width: 95, valueGetter: (params) => params.row.ProviderFirstName + " " + params.row.ProviderLastName },
        { field: "ProviderNPI", headerName: "Provider NPI", width: 95 },
        { field: "Patient", headerName: "Patient", width: 95, valueGetter: (params) => params.row.PatientFirstName + " " + params.row.PatientLastName },
        { field: "PatientInsuranceName", headerName: "Insurance", width: 95 },
        { field: "Edit", headerName: "Edit", width: 95, align: 'center', renderCell: (params) => <IconButton onClick={() => setEditAccession(params.id)}><EditTwoTone /></IconButton>, filterable: false },
    ], []);


    const tabs = [
        {
            label: "All", component: <MUIBackendTable
                jsonKey='datagrid-claimsubmiision1'
                queryString={"/v1/accessions?billed=false"}
                refresh={refresh}
                columns={columns}
                selected={selectedAccessions}
                setSelected={setSelectedAccessions}
            />
        },
        {
            label: "Eligibility", component: <MUIBackendTable
                jsonKey='datagrid-claimsubmiision2'
                queryString={"/v1/accessions?billed=false&stage=1&assignedToClient=false"}
                refresh={refresh}
                columns={columns}
                selected={selectedAccessions}
                setSelected={setSelectedAccessions}
            />
        },
        {
            label: "Modifier", component: <MUIBackendTable
                jsonKey='datagrid-claimsubmiision3'
                queryString={"/v1/accessions?billed=false&stage=2&assignedToClient=false"}
                refresh={refresh}
                columns={columns}
                selected={selectedAccessions}
                setSelected={setSelectedAccessions}
            />
        },
        {
            label: "Diagnosis Validity", component: <MUIBackendTable
                jsonKey='datagrid-claimsubmiision4'
                queryString={"/v1/accessions?billed=false&stage=4&assignedToClient=false"}
                columns={columns}
                refresh={refresh}
                selected={selectedAccessions}
                setSelected={setSelectedAccessions}
            />
        },
        {
            label: "Export", component: <MUIBackendTable
                jsonKey='datagrid-claimsubmiision5'
                queryString={"/v1/accessions?billed=false&stage=3&assignedToClient=false"}
                refresh={refresh}
                columns={columns}
                selected={selectedAccessions}
                setSelected={setSelectedAccessions}
            />
        },
        {
            label: "Assigned to Client", component: <MUIBackendTable
                jsonKey='datagrid-claimsubmiision6'
                queryString={"/v1/accessions?billed=false&assignedToClient=true"}
                refresh={refresh}
                columns={columns}
                selected={selectedAccessions}
                setSelected={setSelectedAccessions}
            />
        },
    ];
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        setSelectedAccessions([]);
    }, [tabValue])

    const updateAccessions = () => {
        setProcessing(true);
        setWarning(null);
        setError(null);

        if (action === "" || !action) {
            setProcessing(false);
            setAction("");
            return;
        }

        if (selectedAccessions.length === 0) {
            setWarning("Select rows to process");
            setAction("");
            setProcessing(false);
            return;
        }

        let postData = {
            Action: action,
            AccessionIds: selectedAccessions,
            StageID: stage,
        };

        const save = async () => {
            setWarning(null)
            try {
                const response = await axios.post("/v1/accessions/update", postData);
                if (response.status >= 200 && response.status < 300) {
                    setRefresh((prevValue) => !prevValue);
                    setProcessing(false);
                    setAction("");
                }
            } catch (error) {
                setError(error.error);
                setProcessing(false);
                setAction("");
            }
        };

        save();
    }


    console.log(stage, action)
    return (
        <MainCard
            title="Claim Scrubbing"
            secondary={
                <>
                    {!processing ? (
                        <IconButton
                            variant="contained"
                            sx={{ mr: 2 }}
                            color="secondary"
                            disabled={processing}
                            onClick={(prevValue) => {
                                setProcessing(true);
                                setRefresh(!prevValue);
                            }}
                        >
                            <RedoOutlined />
                        </IconButton>
                    ) : (
                        <CircularProgress
                            color="info"
                            sx={{ mr: 2 }}
                            size="small"
                        />
                    )}
                    <AccessionOptionMenu
                        setAction={setAction}
                        disabled={processing || selectedAccessions.length < 1}
                        assignedToClient={tabs[tabValue].label === "Assigned to Client"}
                        open={actionMenuOpen}
                        setOpen={setActionMenuOpen}
                    />
                    {["rerun", "bypass"].includes(action) ?
                        <ConfirmDialog
                            open={action !== ""}
                            setOpen={() => setAction("")}
                            onConfirm={updateAccessions}
                            title="Confirm stage update"
                            subtext={`Please select the stage you would like to ${action}.`}
                        >
                            <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                                <InputLabel>Stage</InputLabel>
                                <Select
                                    onChange={(e) => setStage(e.target.value)}
                                >
                                    <MenuItem value={1}>Eligiblity</MenuItem>
                                    <MenuItem value={2}>Modifier</MenuItem>
                                    <MenuItem value={4}>Diagnosis Validity</MenuItem>
                                    <MenuItem value={3}>AMD Output</MenuItem>
                                </Select>
                            </FormControl>
                        </ConfirmDialog>
                        :
                        <ConfirmDialog
                            open={action !== ""}
                            setOpen={() => setAction("")}
                            onConfirm={updateAccessions}
                            title="Confirm reassignment"
                            subtext={`Are you sure you want to assign these claims to the client?.`}
                        >
                        </ConfirmDialog>}
                </>
            }
        >
            <DetailDialog
                open={editAccession !== null}
                setOpen={() => { setEditAccession(null) }}
                title="Accession Details"
            >
                <AccessionDetail id={editAccession} />
            </DetailDialog>
            <ErrorAlert error={error} />
            <WarningAlert warning={warning} />
            <Box height={`calc(100vh - 385px)`}>
                <CustomTabs value={tabValue} setValue={setTabValue} tabs={tabs} />
            </Box>
        </MainCard>
    );
};

export { GetStatusChip };
export default ClaimScrubbingPage;
