import React from "react";
import DataTable from "components/DataTable.js";
import { DataGridPro, gridClasses, GridToolbar } from "@mui/x-data-grid-pro";
import { useState, useEffect, useCallback } from "react";
import axios from "utils/axios";
import { styled, alpha } from '@mui/material/styles';

function PaginatedDataTable({ jsonKey, columns, queryString, setSelected, refresh, selected, ...props }) {
    const [paginationModel, setPaginationModel] = useState({ pageSize: 500, page: 0 })
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [rowCount, setRowCount] = useState(0);
    const [rowCountState, setRowCountState] = useState(rowCount);
    const [queryOptions, setQueryOptions] = useState({ items: [], logicOperator: 'and', quickFilterExcludeHiddenColumns: false, quickFilterLogicOperator: 'and', quickFilterValues: [] });

    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            rowCount !== undefined ? rowCount : prevRowCountState,
        );
    }, [rowCount, setRowCountState]);

    const getPaginateQueryParams = (queryString) => {
        if (queryString.includes("?")) {
            return `${queryString}&page=${paginationModel.page}&limit=${paginationModel.pageSize}`
        }
        return `${queryString}?page=${paginationModel.page}&limit=${paginationModel.pageSize}`
    }

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true)
                const response = await axios.post(`${getPaginateQueryParams(queryString)}&search=${queryOptions.quickFilterValues.join(" ")}`, queryOptions);
                setRowCount(response.data.Count)
                setData(response.data.Data)
            } catch (e) {
                setError("Failed to load data")
            } finally {
                setLoading(false);
            }
        };

        init();
    }, [refresh, queryString, paginationModel, queryOptions]);

    const onFilterChange = useCallback((filterModel, event) => {
        console.log(filterModel, event)
        setQueryOptions({ ...filterModel });
    }, [])

    console.log(queryOptions)

    return <DataTable
        jsonKey={jsonKey}
        error={error}
        data={data}
        loading={loading}
        columns={columns}
        setSelected={setSelected}
        selected={selected}
        rowCount={rowCountState}
        pageSizeOptions={[50, 500, 1000, 2500, 5000, 10000]}
        pagination
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        {...props}
    />
}

function MUIBackendTable({ jsonKey, columns, queryString, setSelected, refresh, selected, ...props }) {
    const [state, setState] = useState({
        pagination: { paginationModel: { pageSize: 500, page: 0 } },
        filter: { filterModel: { items: [], logicOperator: 'and', quickFilterExcludeHiddenColumns: false, quickFilterLogicOperator: 'and', quickFilterValues: [] } },
        sorting: { sortModel: [] },
    })

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [rowCount, setRowCount] = useState(0);
    const [rowCountState, setRowCountState] = useState(rowCount);

    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            rowCount !== undefined ? rowCount : prevRowCountState,
        );
    }, [rowCount, setRowCountState]);

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        const init = async () => {
            try {
                if (!signal.aborted) {
                    setLoading(true)
                    const response = await axios.post(`${queryString}`, state, { signal: signal });
                    setRowCount(response.data.Count)
                    setData(response.data.Data)
                }
            } catch (e) {
                if (!signal.aborted) {
                    setError("Failed to load data")
                }
            } finally {
                if (!signal.aborted) {
                    setLoading(false);
                }
            }
        };

        init();
        return () => {
            abortController.abort();
        }

    }, [refresh, queryString, state]);

    const handlePaginationModelChange = (model) => {
        setState((state) => ({
            ...state,
            pagination: { paginationModel: model },
        }));
    };

    const handleSortModelChange = (model) => {
        setState((state) => ({
            ...state,
            sorting: { sortModel: model },
        }));
    }

    const handleFilterModelChange = (model) => {
        setState((state) => ({
            ...state,
            filter: { filterModel: model },
        }));
    }

    return <DataTable
        jsonKey={jsonKey}
        error={error}
        data={data}
        loading={loading}
        columns={columns}
        setSelected={setSelected}
        selected={selected}
        rowCount={rowCountState}
        pageSizeOptions={[500, 1000, 2500, 5000, 10000, 100000]}
        pagination
        paginationMode="server"
        // @ts-ignore
        paginationModel={state?.pagination?.paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        sortingMode="server"
        sortModel={state?.sorting?.sortModel}
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        filterModel={state?.filter?.filterModel}
        onFilterModelChange={handleFilterModelChange}

        {...props}
    />
}


export { MUIBackendTable, PaginatedDataTable };
export default PaginatedDataTable;
